import React from 'react';
import Link from './Link';
import { useTranslation } from 'react-i18next';

const Together = props => {
  const { t } = useTranslation();
  return (
    <>
      <div className='contact global-section global-padding'>
        <p className='together'>{t('together.text')}</p>
        <div className='btn'>
          <a href='#contact'>
            <button className='contactB'>{t('together.button')}</button>
          </a>
        </div>
        <style jsx>{`
          .contact {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: space-around;
          }

          .together {
            font-size: 64px;
            font-weight: bold;
            max-width: 500px;
            text-align: center;
          }

          .contactB {
            align-items: center;
          }

          .btn {
            display: flex;
            align-items: center;
          }

          @media only screen and (max-width: 800px) {
            .contact {
              display: flex;
              flex-direction: column;
              align-items: center;
            }
            .together {
              font-size: 32px;
              font-weight: bold;
              display: flex;
              text-align: center;
            }
          }
        `}</style>
      </div>
    </>
  );
};

export default Together;
