import React from 'react';

const FeatureSection = props => {
  const Illustration = props.illustration;
  return (
    <>
      <div className='content'>
        <div className='illustration'>
          <Illustration width='100%' height='100%' />
        </div>
        <div className='description'>
          <h3>{props.title}</h3>
          <p>{props.description}</p>
        </div>
      </div>

      <style jsx>{`
        .content {
          justify-content: center;
          display: flex;
          flex-direction: ${props.reverse ? 'row-reverse' : 'row'};
          margin: 80px 0;
        }

        .illustration {
          flex: 0.3;
        }

        .description {
          max-width: 450px;
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin: 0 30px;
        }

        @media only screen and (max-width: 768px) {
          .content {
            flex-direction: column;
            align-items: center;
          }

          .illustration {
            width: 50vw;
            margin-bottom: 20px;
          }
        }
      `}</style>
    </>
  );
};

export default FeatureSection;
