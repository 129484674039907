import React from 'react';
import GearsIllustration from '../../../assets/illustrations/gears.svg';
import PalleteIllustration from '../../../assets/illustrations/pallete.svg';
import ProjectsIllustration from '../../../assets/illustrations/projects-illustration.svg';
import FeatureSection from '../FeatureSection';

import { useTranslation } from 'react-i18next';

const OurFeaturesSection = () => {
  const { t } = useTranslation();
  return (
    <>
      <FeatureSection
        illustration={GearsIllustration}
        title={t('index.ourFeaturesSection.weAdaptTitle')}
        description={t('index.ourFeaturesSection.weAdaptText')}
      />
      <FeatureSection
        illustration={PalleteIllustration}
        title={t('index.ourFeaturesSection.passionTitle')}
        description={t('index.ourFeaturesSection.passionText')}
        reverse
      />
      <FeatureSection
        illustration={ProjectsIllustration}
        title={t('index.ourFeaturesSection.detailTitle')}
        description={t('index.ourFeaturesSection.detailText')}
      />
    </>
  );
};

const ContactLink = ({ icon, link, text }) => {
  const Icon = icon;
  return (
    <>
      <a href={link}>
        <div className='contact-link'>
          <Icon width='1.3em' height='1.3em' />
          <li>{text}</li>
        </div>
      </a>

      <style jsx>{`
        .contact-link {
          display: flex;
        }

        li {
          list-style: none;
          font-size: 16px;
          margin-left: 13px;
        }
      `}</style>
    </>
  );
};

export default OurFeaturesSection;
