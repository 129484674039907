import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { useTranslation } from 'react-i18next';

const CustomersSection = () => {
  const { t } = useTranslation();
  let data = useStaticQuery(graphql`
    query {
      vazcorLogo: file(relativePath: { eq: "customers/vazcor-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      toldosLogo: file(relativePath: { eq: "customers/toldos-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      finitsLogo: file(relativePath: { eq: "customers/finits-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      yesLogo: file(relativePath: { eq: "customers/yes-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      aieraLogo: file(relativePath: { eq: "customers/aiera-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      beatbanksLogo: file(relativePath: { eq: "customers/beatbanks-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mindboxLogo: file(relativePath: { eq: "customers/mindbox-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ahuMediaLogo: file(relativePath: { eq: "customers/ahu-media-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      armetLogo: file(relativePath: { eq: "customers/armet-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ayzerLogo: file(relativePath: { eq: "customers/ayzer-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ccnLogo: file(relativePath: { eq: "customers/ccn-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      decantLogo: file(relativePath: { eq: "customers/decant-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      gossLogo: file(relativePath: { eq: "customers/goss-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      implanLogo: file(relativePath: { eq: "customers/implan-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      itsLogo: file(relativePath: { eq: "customers/its-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      masMenuLogo: file(relativePath: { eq: "customers/mas-menu-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      pitchersLogo: file(relativePath: { eq: "customers/pitchers-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      saltilloLogo: file(relativePath: { eq: "customers/saltillo-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      cargoLogo: file(relativePath: { eq: "customers/cargo-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      tiendaTecLogo: file(relativePath: { eq: "customers/tiendatec-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <>
      <div className='centered content global-margin'>
        <h2>{t('index.customersSection.title')}</h2>
        <div className='logos'>
          <CustomerLogo fluid={data.vazcorLogo.childImageSharp.fluid} alt='vazcor' />
          <CustomerLogo fluid={data.toldosLogo.childImageSharp.fluid} alt='toldos' />
          <CustomerLogo fluid={data.finitsLogo.childImageSharp.fluid} alt='finits' />
          <CustomerLogo fluid={data.yesLogo.childImageSharp.fluid} alt='yesone' />
          <CustomerLogo fluid={data.beatbanksLogo.childImageSharp.fluid} alt='beatbanks' />
          <CustomerLogo fluid={data.aieraLogo.childImageSharp.fluid} alt='aiera' />
          <CustomerLogo fluid={data.mindboxLogo.childImageSharp.fluid} alt='mindbox' />
          <CustomerLogo fluid={data.ahuMediaLogo.childImageSharp.fluid} alt='ahu-media' />
          <CustomerLogo fluid={data.armetLogo.childImageSharp.fluid} alt='armet' />
          <CustomerLogo fluid={data.ayzerLogo.childImageSharp.fluid} alt='ayzer' />
          <CustomerLogo fluid={data.ccnLogo.childImageSharp.fluid} alt='ccn' />
          <CustomerLogo fluid={data.decantLogo.childImageSharp.fluid} alt='decant' />
          <CustomerLogo fluid={data.gossLogo.childImageSharp.fluid} alt='goss' />
          <CustomerLogo fluid={data.implanLogo.childImageSharp.fluid} alt='implan' />
          <CustomerLogo fluid={data.itsLogo.childImageSharp.fluid} alt='its' />
          <CustomerLogo fluid={data.masMenuLogo.childImageSharp.fluid} alt='mas-menu' />
          <CustomerLogo fluid={data.pitchersLogo.childImageSharp.fluid} alt='pitchers' />
          <CustomerLogo fluid={data.saltilloLogo.childImageSharp.fluid} alt='saltillo' />
          <CustomerLogo fluid={data.cargoLogo.childImageSharp.fluid} alt='cargo' />
          <CustomerLogo fluid={data.tiendaTecLogo.childImageSharp.fluid} alt='tienda-tec' />
        </div>
      </div>

      <style jsx>{`
        .centered {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }

        .logos {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
        }
      `}</style>
    </>
  );
};

const CustomerLogo = ({ fluid }) => {
  const imageSize = {
    width: '90px',
    height: '100px'
  };
  return (
    <>
      <div className='img-container'>
        <Img
          style={{ height: imageSize.height, width: imageSize.width }}
          alt={`vazcor-logo`}
          imgStyle={{ objectFit: 'contain' }}
          fluid={fluid}
        />
      </div>
      <style jsx>{`
        .img-container {
          margin: 20px;
        }
      `}</style>
    </>
  );
};

export default CustomersSection;
