import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Link from '../Link';
import { useTranslation } from 'react-i18next';

const CoursesPreview = () => {
  const { t } = useTranslation();
  let data = useStaticQuery(graphql`
    query {
      coursesImage: file(relativePath: { eq: "courses-index.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <>
      <div className='content global-light'>
        <Img
          style={{ height: '50vh' }}
          imgStyle={{ objectPosition: '80% 0' }}
          fluid={data.coursesImage.childImageSharp.fluid}
        />
        <div className='text-content'>
          <h2>{t('index.coursesPreview.title')}</h2>
          <p>{t('index.coursesPreview.text')}</p>
          <Link to='/courses'>
            <button>{t('index.coursesPreview.button')}</button>
          </Link>
        </div>
      </div>
      <style jsx>{`
        .text-content {
          padding: 30px;
        }
      `}</style>
    </>
  );
};

export default CoursesPreview;
