import React from 'react';
import Layout from '../components/Layout';
import Together from '../components/Together';
import Seo from '../components/Seo';
import Link from '../components/Link';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import OurFeaturesSection from '../components/home/OurFeaturesSection';
import CustomersSection from '../components/home/CustomersSection';
import ProjectsPreview from '../components/home/ProjectsPreview';
import CoursesPreview from '../components/home/CoursesPreview';
import withTranslations from '../components/hoc/withTranslations';
import { useTranslation } from 'react-i18next';

const IndexPage = () => {
  const { t, i18n } = useTranslation();
  let data = useStaticQuery(graphql`
    query {
      indexImage: file(relativePath: { eq: "new-index.png" }) {
        childImageSharp {
          fluid(maxWidth: 1800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      mobileApps: file(relativePath: { eq: "service-previews/service-preview-02.png" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      webDevelopment: file(relativePath: { eq: "service-previews/service-preview-03.png" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      cloudServices: file(relativePath: { eq: "service-previews/service-preview-01.png" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Layout section={'HOME'} manualMargin manualPadding>
      <Seo
        title={t('index.seoTitle')}
        description={t('index.seoDescription')}
        keywords={[
          'desarrollo',
          'web',
          'aplicaciones moviles',
          'positive zero',
          'sistemas',
          'desarrollo web',
          'programadores',
          'software',
          'saltillo'
        ]}
      />

      <section>
        <div className='banner'>
          <div className='banner-illustration'>
            <Img
              alt={`illustration`}
              style={{ maxHeight: 1300 }}
              imgStyle={{ objectFit: 'contain', objectPosition: 'left', pointerEvents: 'none' }}
              fluid={data.indexImage.childImageSharp.fluid}
            />
          </div>
          <div className='banner-right' style={{ padding: 20 }}>
            <div className='banner-message global-fade-in' style={{ maxWidth: 700 }}>
              <span className='banner-text'>{t('index.bannerText')}</span>
              <span style={{ fontWeight: 'bold' }}>{t('index.bannerTextBold')}</span>
              <span className='banner-text'>{t('index.bannerTextContinued')}</span>
            </div>
            <div className='global-fade-in-long'>
              <a href='#contact'>
                <button>{t('index.buttonText')}</button>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className='global-section intro global-light'>
        <div className='centered global-margin global-padding'>
          <h2>{t('index.intro.title')}</h2>
          <p className='color-black'>{t('index.intro.text')}</p>
          <div className='service-previews'>
            <ServicePreview name={t('index.intro.mobileApps')} image={data.mobileApps.childImageSharp.fluid} />
            <ServicePreview name={t('index.intro.webDevelopment')} image={data.webDevelopment.childImageSharp.fluid} />
            <ServicePreview name={t('index.intro.cloudServices')} image={data.cloudServices.childImageSharp.fluid} />
          </div>

          <Link to='/services'>
            <button>{t('index.intro.buttonServices')}</button>
          </Link>
        </div>
      </section>

      <section className='global-section global-padding global-margin'>
        <OurFeaturesSection />
        <div className='methodology-banner'>
          <p style={{ maxWidth: '600px' }}>{t('index.ourFeaturesSection.methodologyBanner')}</p>
          <Link to='/methodology'>
            <button>{t('index.ourFeaturesSection.methodologyButton')}</button>
          </Link>
        </div>
      </section>

      <section className='global-section global-light customers'>
        <CustomersSection />
      </section>

      <section className='global-section global-margin global-padding'>
        <ProjectsPreview />
      </section>

      <section className='global-section global-padding global-margin'>
        <CoursesPreview />
      </section>

      <section>
        <Together />
      </section>

      <style jsx>{`
        .banner {
          display: flex;
          margin-top: 30px;
        }

        .banner-illustration {
          flex: 1;
          max-height: 900px;
        }

        .banner-right {
          flex: 1;
          margin-top: 150px;
          margin-left: 50px;
        }

        .banner-message {
          font-size: 3vw;
          margin-bottom: 20px;
        }

        .methodology-banner {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
          margin-top: 30px;
        }

        .banner-text {
          margin-bottom: 20px;
        }

        .centered {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }

        .service-previews {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-evenly;
          margin-bottom: 50px;
        }

        .intro {
          padding: 130px 0;
          clip-path: polygon(0 80px, 100% 0, 100% 100%, 0% 100%);
        }

        .customers {
          padding: 130px 0;
          clip-path: polygon(30vw 8vh, 100% 0, 100% 100%, 0 100%, 0 0);
        }

        .color-black {
          color: var(--primary-black);
        }

        @media only screen and (max-width: 968px) {
          .banner {
            flex-direction: column;
            flex-direction: column-reverse;
          }

          .banner-illustration {
            width: 100%;
            max-height: 500px;
          }

          .banner-right {
            margin-top: 0;
            margin-left: 0px;
            font-size: 40px;
            margin-bottom: 50px;
          }

          .banner-message {
            font-size: 35px;
          }
        }
      `}</style>
    </Layout>
  );
};

const ServicePreview = ({ name, image }) => {
  return (
    <>
      <div className='tile global-dark'>
        <Img style={{ height: '170px', width: '100%' }} alt={`${name} preview`} fluid={image} />
        <div className='bottom '>
          <span>{name}</span>
        </div>
      </div>

      <style jsx>{`
        span {
          font-weight: 600;
          font-size: 24px;
        }

        .tile {
          width: 280px;
          flex-direction: column;
          margin: 15px;
          box-shadow: -10px 0px 30px rgba(0, 0, 0, 0.25);
        }

        .bottom {
          padding: 30px;
          display: flex;
          align-items: center;
        }
      `}</style>
    </>
  );
};

export default withTranslations()(IndexPage);
