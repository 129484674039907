import React from 'react';
import Link from '../Link';
import styles from '../../styles/home/home.module.css';

import { useTranslation } from 'react-i18next';

const ProjectsPreview = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className='container'>
        <div className='imageStack'>
          <Link to='/projects#haztuhorario'>
            <div aria-label='haztuhorario' className={styles.haztuhorarioPreview} />
          </Link>

          <Link to='/projects#gymsys'>
            <div aria-label='gymsys' className={styles.gymsysPreview} />
          </Link>

          <Link to='/projects#tap'>
            <div aria-label='tap' className={styles.tapPreview} />
          </Link>
        </div>

        <div>
          <h3>{t('index.projectsPreview.title')}</h3>
          <p>{t('index.projectsPreview.text')}</p>
          <Link to={'/projects'}>
            <button>{t('index.projectsPreview.button')}</button>
          </Link>
        </div>
      </div>
      <style jsx>{`
        .container {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .imageStack {
          position: relative;
          height: 450px;
          width: 450px;
          flex: none;
          margin: 30px;
        }

        .imageStack div {
          border-radius: 10px;
          background-size: contain;
          position: absolute;
          -webkit-box-shadow: 0px -4px 27px 0px rgba(0, 0, 0, 0.41);
          -moz-box-shadow: 0px -4px 27px 0px rgba(0, 0, 0, 0.41);
          box-shadow: 0px -4px 27px 0px rgba(0, 0, 0, 0.41);
        }

        .imageStack div:hover {
          /*filter: brightness(90%);*/
          cursor: pointer;
          transform: scale(1.02);
          z-index: 10;
          transition: 0.1s;
        }

        @media screen and (max-width: 768px) {
          .container {
            flex-direction: column;
          }

          .imageStack {
            height: 80vw;
            width: 80vw;
          }
        }
      `}</style>
    </>
  );
};

export default ProjectsPreview;
